import SmoothScrollPolyfill from 'smoothscroll-polyfill';
import Swiper, {Navigation, Pagination} from 'swiper';
import Macy from 'macy';
import ahoy from 'ahoy.js';
import 'fslightbox';

/**
 * Setup swiper
 */
Swiper.use([Navigation, Pagination]);

/**
 * Scheduler for initialization
 */
const schedulerMessageName = 'schedule-task';
const schedulerTasks = [];
const schedulerInit = () => {
    window.addEventListener('message', (event) => {
        if (event.source !== window) return;
        if (event.data !== schedulerMessageName) return;

        event.stopPropagation();
        if (schedulerTasks.length > 0) schedulerTasks.shift()();
    }, true);
};
const schedule = (fn) => {
    schedulerTasks.push(fn);
    window.postMessage(schedulerMessageName,'*');
};

/**
 * Activate polyfill
 */
const activateSmoothScrollPolyfill = () => {
    SmoothScrollPolyfill.polyfill();
}

/**
 * Init smooth scrolling when clicking on anchor link
 */
const anchorScrollInit = () => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
};

/**
 * Init research slider
 */
const researchSliderInit = () => {
    new Swiper('.research-slider', {
        modules: [Navigation, Pagination],
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button.-next',
            prevEl: '.swiper-button.-prev',
        },
        simulateTouch: false,
        spaceBetween: 20,
        autoHeight: true,
    });
};

/**
 * Init reviews show all button and masonry
 */
const reviewsInit = () => {
    const reviewsElement = document.querySelector('.reviews');

    if (!reviewsElement) {
        return;
    }

    const reviewsShowAllElement = reviewsElement.querySelector('.reviews-show-all');
    reviewsShowAllElement.addEventListener('click', () => {
        reviewsShowAllElement.style.display = 'none';
        reviewsElement.classList.add('active');
    });

    Macy({
        container: '.reviews-cards',
        margin: {
            x: 24,
            y: 24,
        },
        columns: 3,
        breakAt: {
            992: {
                columns: 2,
            },
            768: {
                columns: 1,
                margin: {
                    x: 0,
                    y: 16,
                },
            },
        },
    });
};

/**
 * Init products slider
 */
const productsSliderInit = () => {
    let productsSlider = undefined;

    function enableProductsSlider() {
        productsSlider = new Swiper('.products-slider', {
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button.-next',
                prevEl: '.swiper-button.-prev',
            },
            simulateTouch: false,
            spaceBetween: 46,
            slidesPerView: 3,
            breakpoints: {
                992: {
                    slidesPerView: 4,
                },
            }
        });
    }

    function disableProductsSlider() {
        if (productsSlider !== undefined) {
            productsSlider.destroy(true, true);
        }
    }

    const breakpoint = window.matchMedia('(max-width:768px)');
    const breakpointChecker = function breakpointChecker(event) {
        event.matches ? disableProductsSlider() : enableProductsSlider();
    };

    breakpoint.addEventListener('change', breakpointChecker);
    breakpointChecker(breakpoint);
};

/**
 * Init countdown
 */
const countdownInit = () => {
    const tryCountdown = document.querySelector('.try-countdown');

    if (!tryCountdown) {
        return;
    }

    const deadlineTimestamp = parseInt(tryCountdown.getAttribute('data-deadline-timestamp'), 10);

    const INTERVAL = 500;
    const leftUntil = (deadlineTimestamp) => {
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const leftSeconds = deadlineTimestamp - currentTimestamp;

        return leftSeconds < 0 ? 0 : leftSeconds;
    };

    const updateView = () => {
        const seconds = leftUntil(deadlineTimestamp);
        const second = seconds % 60;
        const minutes = Math.floor(seconds / 60);
        const minute = minutes % 60;
        const hours = Math.floor(minutes / 60);

        const updateUnit = (element, value, label) => {
            const zeroPad = (num, places) => String(num).padStart(places, '0');
            const pluralize = (number, label) => {
                if (number === 0) return label['zero'];
                if ((number % 10 === 1) && (number % 100 !== 11)) return label['one'];
                if ([2, 3, 4].includes(number % 10) && ![12, 13, 14].includes(number % 100)) return label['few'];

                return label['other'];
            };

            element.querySelector('.value').innerHTML = zeroPad(value, 2);
            element.querySelector('.label').innerHTML = pluralize(value, label);
        };

        updateUnit(tryCountdown.querySelector('.hours'), hours, {
            'zero': 'часов',
            'one': 'час',
            'few': 'часа',
            'other': 'часов',
        });

        updateUnit(tryCountdown.querySelector('.minutes'), minute, {
            'zero': 'мин',
            'one': 'мин',
            'few': 'мин',
            'other': 'мин',
        });

        updateUnit(tryCountdown.querySelector('.seconds'), second, {
            'zero': 'сек',
            'one': 'сек',
            'few': 'сек',
            'other': 'сек',
        });
    };

    setInterval(updateView, INTERVAL);
    updateView();
};

/**
 * Init analytics
 */
const analyticsObservableInit = () => {
    if (typeof window.IntersectionObserver !== 'function') {
        ahoy.track('observe:init_failure');
        return;
    }

    ahoy.track('observe:init_success');

    const IntersectionObserver = window.IntersectionObserver;
    const viewportHeight = window.innerHeight;
    const observableElements = Array.from(document.querySelectorAll('[data-observable]'));
    const observableElementsWithHeight = observableElements.map((element) => {
        return [element, element.getBoundingClientRect().height];
    });

    const callback = function (entries,) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                ahoy.track(`observe:${entry.target.getAttribute('data-observable')}`);
            }
        });
    };

    observableElementsWithHeight.forEach(([element, height]) => {
        const threshold = viewportHeight >= height ? 1.0 : Math.floor(viewportHeight * 10 / height) / 10;
        new IntersectionObserver(callback, {
            threshold: threshold
        }).observe(element);
    });
};


const analyticsTrackableInit = () => {
    const clickTrackableElements = Array.from(document.querySelectorAll('[data-click-trackable]'));

    clickTrackableElements.forEach(element => {
        element.addEventListener('click', function (_) {
            ahoy.track(`trackable:click_${element.getAttribute('data-click-trackable')}`);
        });
    });

};

/**
 * Sync tasks
 */
activateSmoothScrollPolyfill();
anchorScrollInit();
analyticsObservableInit();
analyticsTrackableInit();

/**
 * Async tasks
 */
schedulerInit();
schedule(researchSliderInit);
schedule(reviewsInit);
schedule(productsSliderInit);
schedule(countdownInit);